module.exports = {
  v:"5.6.3",
  fr:24,
  ip:0,
  op:48,
  w:310,
  h:290,
  nm:"Logo Kick",
  ddd:0,
  assets:[

  ],
  layers:[
     {
        ddd:0,
        ind:1,
        ty:4,
        nm:"A1 Outlines",
        sr:1,
        ks:{
           o:{
              a:0,
              k:100,
              ix:11
           },
           r:{
              a:0,
              k:0,
              ix:10
           },
           p:{
              a:0,
              k:[
                 155,
                 145,
                 0
              ],
              ix:2
           },
           a:{
              a:0,
              k:[
                 155,
                 145,
                 0
              ],
              ix:1
           },
           s:{
              a:0,
              k:[
                 100,
                 100,
                 100
              ],
              ix:6
           }
        },
        ao:0,
        shapes:[
           {
              ty:"gr",
              it:[
                 {
                    ind:0,
                    ty:"sh",
                    ix:1,
                    ks:{
                       a:0,
                       k:{
                          i:[
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ]
                          ],
                          o:[
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ]
                          ],
                          v:[
                             [
                                -42.369,
                                -38.604
                             ],
                             [
                                -10.227,
                                38.604
                             ],
                             [
                                42.37,
                                38.604
                             ],
                             [
                                10.198,
                                -38.604
                             ]
                          ],
                          c:true
                       },
                       ix:2
                    },
                    nm:"Path 1",
                    mn:"ADBE Vector Shape - Group",
                    hd:false
                 },
                 {
                    ty:"fl",
                    c:{
                       a:0,
                       k:[
                          0,
                          0,
                          0,
                          1
                       ],
                       ix:4
                    },
                    o:{
                       a:0,
                       k:100,
                       ix:5
                    },
                    r:1,
                    bm:0,
                    nm:"Fill 1",
                    mn:"ADBE Vector Graphic - Fill",
                    hd:false
                 },
                 {
                    ty:"tr",
                    p:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:0.833,
                                y:1
                             },
                             o:{
                                x:0.333,
                                y:0
                             },
                             t:0,
                             s:[
                                259,
                                279
                             ],
                             e:[
                                259.35,
                                278.953
                             ],
                             to:[
                                0.058,
                                -0.008
                             ],
                             ti:[
                                -0.058,
                                0.008
                             ]
                          },
                          {
                             i:{
                                x:0.833,
                                y:0.833
                             },
                             o:{
                                x:0.333,
                                y:0.333
                             },
                             t:12,
                             s:[
                                259.35,
                                278.953
                             ],
                             e:[
                                259.35,
                                278.953
                             ],
                             to:[
                                0,
                                0
                             ],
                             ti:[
                                0,
                                0
                             ]
                          },
                          {
                             i:{
                                x:0.667,
                                y:0.817
                             },
                             o:{
                                x:0.333,
                                y:0
                             },
                             t:17,
                             s:[
                                259.35,
                                278.953
                             ],
                             e:[
                                238.763,
                                257.727
                             ],
                             to:[
                                -0.687,
                                -3.095
                             ],
                             ti:[
                                1.071,
                                4.76
                             ]
                          },
                          {
                             i:{
                                x:0.667,
                                y:1
                             },
                             o:{
                                x:0.333,
                                y:0.069
                             },
                             t:22,
                             s:[
                                238.763,
                                257.727
                             ],
                             e:[
                                254.264,
                                256.043
                             ],
                             to:[
                                -0.251,
                                -1.113
                             ],
                             ti:[
                                -0.006,
                                -0.07
                             ]
                          },
                          {
                             i:{
                                x:0.667,
                                y:1
                             },
                             o:{
                                x:0.333,
                                y:0
                             },
                             t:23,
                             s:[
                                254.264,
                                256.043
                             ],
                             e:[
                                259,
                                279
                             ],
                             to:[
                                0.03,
                                0.372
                             ],
                             ti:[
                                -0.789,
                                -3.826
                             ]
                          },
                          {
                             t:27
                          }
                       ],
                       ix:2
                    },
                    a:{
                       a:0,
                       k:[
                          2.82,
                          38.5
                       ],
                       ix:1
                    },
                    s:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:[
                                   0.667,
                                   0.667
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333,
                                   0.333
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:15,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                100,
                                67
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667,
                                   0.667
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333,
                                   0.333
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:17,
                             s:[
                                100,
                                67
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667,
                                   0.667
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333,
                                   0.333
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:19,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.833,
                                   0.833
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.167,
                                   0.167
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:20,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                79,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667,
                                   0.667
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.167,
                                   0.167
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:21,
                             s:[
                                79,
                                100
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             t:23
                          }
                       ],
                       ix:3
                    },
                    r:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:17,
                             s:[
                                0
                             ],
                             e:[
                                -7
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   0.955
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:20,
                             s:[
                                -7
                             ],
                             e:[
                                37.464
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   -0.072
                                ]
                             },
                             t:23,
                             s:[
                                37.464
                             ],
                             e:[
                                0
                             ]
                          },
                          {
                             t:27
                          }
                       ],
                       ix:6
                    },
                    o:{
                       a:0,
                       k:100,
                       ix:7
                    },
                    sk:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:[
                                   0.833
                                ],
                                y:[
                                   0.833
                                ]
                             },
                             o:{
                                x:[
                                   0.167
                                ],
                                y:[
                                   0.167
                                ]
                             },
                             t:20,
                             s:[
                                0
                             ],
                             e:[
                                -4
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.833
                                ],
                                y:[
                                   0.833
                                ]
                             },
                             o:{
                                x:[
                                   0.167
                                ],
                                y:[
                                   0.167
                                ]
                             },
                             t:21,
                             s:[
                                -4
                             ],
                             e:[
                                0
                             ]
                          },
                          {
                             t:22
                          }
                       ],
                       ix:4
                    },
                    sa:{
                       a:0,
                       k:0,
                       ix:5
                    },
                    nm:"Transform"
                 }
              ],
              nm:"SmallLeg",
              np:2,
              cix:2,
              bm:0,
              ix:1,
              mn:"ADBE Vector Group",
              hd:false
           },
           {
              ty:"gr",
              it:[
                 {
                    ind:0,
                    ty:"sh",
                    ix:1,
                    ks:{
                       a:0,
                       k:{
                          i:[
                             [
                                0,
                                -15.071
                             ],
                             [
                                -15.071,
                                0
                             ],
                             [
                                0,
                                15.072
                             ],
                             [
                                15.072,
                                0
                             ]
                          ],
                          o:[
                             [
                                0,
                                15.072
                             ],
                             [
                                15.072,
                                0
                             ],
                             [
                                0,
                                -15.071
                             ],
                             [
                                -15.071,
                                0
                             ]
                          ],
                          v:[
                             [
                                -27.305,
                                0
                             ],
                             [
                                -0.001,
                                27.305
                             ],
                             [
                                27.305,
                                0
                             ],
                             [
                                -0.001,
                                -27.305
                             ]
                          ],
                          c:true
                       },
                       ix:2
                    },
                    nm:"Path 1",
                    mn:"ADBE Vector Shape - Group",
                    hd:false
                 },
                 {
                    ty:"fl",
                    c:{
                       a:0,
                       k:[
                          0,
                          0,
                          0,
                          1
                       ],
                       ix:4
                    },
                    o:{
                       a:0,
                       k:100,
                       ix:5
                    },
                    r:1,
                    bm:0,
                    nm:"Fill 1",
                    mn:"ADBE Vector Graphic - Fill",
                    hd:false
                 },
                 {
                    ty:"tr",
                    p:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:0.667,
                                y:0.667
                             },
                             o:{
                                x:0.167,
                                y:0.167
                             },
                             t:2,
                             s:[
                                208.381,
                                134.963
                             ],
                             e:[
                                208.381,
                                134.963
                             ],
                             to:[
                                0,
                                0
                             ],
                             ti:[
                                0,
                                0
                             ]
                          },
                          {
                             i:{
                                x:0.667,
                                y:0.667
                             },
                             o:{
                                x:0.167,
                                y:0.167
                             },
                             t:4,
                             s:[
                                208.381,
                                134.963
                             ],
                             e:[
                                208.381,
                                134.963
                             ],
                             to:[
                                0,
                                0
                             ],
                             ti:[
                                0,
                                0
                             ]
                          },
                          {
                             i:{
                                x:0.833,
                                y:1
                             },
                             o:{
                                x:0.167,
                                y:0
                             },
                             t:7,
                             s:[
                                208.381,
                                134.963
                             ],
                             e:[
                                184.612,
                                242.42
                             ],
                             to:[
                                -3.962,
                                17.91
                             ],
                             ti:[
                                3.962,
                                -17.91
                             ]
                          },
                          {
                             i:{
                                x:0.833,
                                y:0.833
                             },
                             o:{
                                x:0.333,
                                y:0.333
                             },
                             t:12,
                             s:[
                                184.612,
                                242.42
                             ],
                             e:[
                                184.612,
                                242.42
                             ],
                             to:[
                                0,
                                0
                             ],
                             ti:[
                                0,
                                0
                             ]
                          },
                          {
                             i:{
                                x:0.833,
                                y:0.833
                             },
                             o:{
                                x:0.333,
                                y:0.333
                             },
                             t:15,
                             s:[
                                184.612,
                                242.42
                             ],
                             e:[
                                184.612,
                                242.42
                             ],
                             to:[
                                0,
                                0
                             ],
                             ti:[
                                0,
                                0
                             ]
                          },
                          {
                             i:{
                                x:0.833,
                                y:1
                             },
                             o:{
                                x:0.333,
                                y:0
                             },
                             t:19,
                             s:[
                                184.612,
                                242.42
                             ],
                             e:[
                                199.866,
                                241.819
                             ],
                             to:[
                                2.542,
                                -0.1
                             ],
                             ti:[
                                7.922,
                                12.348
                             ]
                          },
                          {
                             i:{
                                x:0.667,
                                y:1
                             },
                             o:{
                                x:0.333,
                                y:0
                             },
                             t:22,
                             s:[
                                199.866,
                                241.819
                             ],
                             e:[
                                137.081,
                                168.334
                             ],
                             to:[
                                -7.922,
                                -12.348
                             ],
                             ti:[
                                -4.807,
                                19.231
                             ]
                          },
                          {
                             i:{
                                x:0.667,
                                y:1
                             },
                             o:{
                                x:0.333,
                                y:0
                             },
                             t:27,
                             s:[
                                137.081,
                                168.334
                             ],
                             e:[
                                228.71,
                                126.432
                             ],
                             to:[
                                4.807,
                                -19.231
                             ],
                             ti:[
                                -11.883,
                                5.562
                             ]
                          },
                          {
                             i:{
                                x:0.667,
                                y:1
                             },
                             o:{
                                x:0.167,
                                y:0
                             },
                             t:30,
                             s:[
                                228.71,
                                126.432
                             ],
                             e:[
                                208.381,
                                134.963
                             ],
                             to:[
                                11.883,
                                -5.562
                             ],
                             ti:[
                                3.388,
                                -1.422
                             ]
                          },
                          {
                             t:35
                          }
                       ],
                       ix:2
                    },
                    a:{
                       a:0,
                       k:[
                          0,
                          0
                       ],
                       ix:1
                    },
                    s:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:[
                                   0.583,
                                   0.583
                                ],
                                y:[
                                   1.25,
                                   1.25
                                ]
                             },
                             o:{
                                x:[
                                   0.167,
                                   0.167
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:2,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                120,
                                120
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.583,
                                   0.583
                                ],
                                y:[
                                   0.75,
                                   0.75
                                ]
                             },
                             o:{
                                x:[
                                   0.417,
                                   0.417
                                ],
                                y:[
                                   0.25,
                                   0.25
                                ]
                             },
                             t:4,
                             s:[
                                120,
                                120
                             ],
                             e:[
                                90,
                                90
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.833,
                                   0.833
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.417,
                                   0.417
                                ],
                                y:[
                                   1.25,
                                   1.25
                                ]
                             },
                             t:7,
                             s:[
                                90,
                                90
                             ],
                             e:[
                                80,
                                80
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667,
                                   0.667
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333,
                                   0.333
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:12,
                             s:[
                                80,
                                80
                             ],
                             e:[
                                115,
                                115
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.833,
                                   0.833
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.167,
                                   0.167
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:15,
                             s:[
                                115,
                                115
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.648,
                                   0.648
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.157,
                                   0.157
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:19,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.805,
                                   0.805
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.438,
                                   0.438
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:21,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                79,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.833,
                                   0.833
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.167,
                                   0.167
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:22,
                             s:[
                                79,
                                100
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.62,
                                   0.62
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.159,
                                   0.159
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:24,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.665,
                                   0.665
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.329,
                                   0.329
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:28,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.669,
                                   0.669
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.335,
                                   0.335
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:29,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                81,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.671,
                                   0.671
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.337,
                                   0.337
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:30,
                             s:[
                                81,
                                100
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.732,
                                   0.732
                                ],
                                y:[
                                   1,
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.38,
                                   0.38
                                ],
                                y:[
                                   0,
                                   0
                                ]
                             },
                             t:32,
                             s:[
                                100,
                                100
                             ],
                             e:[
                                100,
                                100
                             ]
                          },
                          {
                             t:34
                          }
                       ],
                       ix:3
                    },
                    r:{
                       a:0,
                       k:0,
                       ix:6
                    },
                    o:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:[
                                   0.583
                                ],
                                y:[
                                   26
                                ]
                             },
                             o:{
                                x:[
                                   0.167
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:2,
                             s:[
                                100
                             ],
                             e:[
                                100
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.583
                                ],
                                y:[
                                   0.625
                                ]
                             },
                             o:{
                                x:[
                                   0.417
                                ],
                                y:[
                                   0.375
                                ]
                             },
                             t:4,
                             s:[
                                100
                             ],
                             e:[
                                0
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.833
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.417
                                ],
                                y:[
                                   -62.5
                                ]
                             },
                             t:7,
                             s:[
                                0
                             ],
                             e:[
                                0
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:12,
                             s:[
                                0
                             ],
                             e:[
                                100
                             ]
                          },
                          {
                             t:15
                          }
                       ],
                       ix:7
                    },
                    sk:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:24,
                             s:[
                                0
                             ],
                             e:[
                                0
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:26,
                             s:[
                                0
                             ],
                             e:[
                                15
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:27,
                             s:[
                                15
                             ],
                             e:[
                                0
                             ]
                          },
                          {
                             t:30
                          }
                       ],
                       ix:4
                    },
                    sa:{
                       a:0,
                       k:0,
                       ix:5
                    },
                    nm:"Transform"
                 }
              ],
              nm:"Dot",
              np:2,
              cix:2,
              bm:0,
              ix:2,
              mn:"ADBE Vector Group",
              hd:false
           },
           {
              ty:"gr",
              it:[
                 {
                    ind:0,
                    ty:"sh",
                    ix:1,
                    ks:{
                       a:0,
                       k:{
                          i:[
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ]
                          ],
                          o:[
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ],
                             [
                                0,
                                0
                             ]
                          ],
                          v:[
                             [
                                29.216,
                                -135.585
                             ],
                             [
                                -85.682,
                                135.585
                             ],
                             [
                                -29.216,
                                135.585
                             ],
                             [
                                85.682,
                                -135.585
                             ]
                          ],
                          c:true
                       },
                       ix:2
                    },
                    nm:"Path 1",
                    mn:"ADBE Vector Shape - Group",
                    hd:false
                 },
                 {
                    ty:"fl",
                    c:{
                       a:0,
                       k:[
                          0,
                          0,
                          0,
                          1
                       ],
                       ix:4
                    },
                    o:{
                       a:0,
                       k:100,
                       ix:5
                    },
                    r:1,
                    bm:0,
                    nm:"Fill 1",
                    mn:"ADBE Vector Graphic - Fill",
                    hd:false
                 },
                 {
                    ty:"tr",
                    p:{
                       a:0,
                       k:[
                          95.097,
                          145
                       ],
                       ix:2
                    },
                    a:{
                       a:0,
                       k:[
                          0,
                          0
                       ],
                       ix:1
                    },
                    s:{
                       a:0,
                       k:[
                          100,
                          100
                       ],
                       ix:3
                    },
                    r:{
                       a:0,
                       k:0,
                       ix:6
                    },
                    o:{
                       a:0,
                       k:100,
                       ix:7
                    },
                    sk:{
                       a:1,
                       k:[
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:26,
                             s:[
                                0
                             ],
                             e:[
                                3
                             ]
                          },
                          {
                             i:{
                                x:[
                                   0.667
                                ],
                                y:[
                                   1
                                ]
                             },
                             o:{
                                x:[
                                   0.333
                                ],
                                y:[
                                   0
                                ]
                             },
                             t:27,
                             s:[
                                3
                             ],
                             e:[
                                0
                             ]
                          },
                          {
                             t:30
                          }
                       ],
                       ix:4
                    },
                    sa:{
                       a:0,
                       k:-6,
                       ix:5
                    },
                    nm:"Transform"
                 }
              ],
              nm:"BigLeg",
              np:2,
              cix:2,
              bm:0,
              ix:3,
              mn:"ADBE Vector Group",
              hd:false
           },
           {
              ty:"gr",
              it:[
                 {
                    ty:"tr",
                    p:{
                       a:0,
                       k:[
                          259.53,
                          281.183
                       ],
                       ix:2
                    },
                    a:{
                       a:0,
                       k:[
                          0,
                          0
                       ],
                       ix:1
                    },
                    s:{
                       a:0,
                       k:[
                          100,
                          100
                       ],
                       ix:3
                    },
                    r:{
                       a:0,
                       k:0,
                       ix:6
                    },
                    o:{
                       a:0,
                       k:100,
                       ix:7
                    },
                    sk:{
                       a:0,
                       k:0,
                       ix:4
                    },
                    sa:{
                       a:0,
                       k:0,
                       ix:5
                    },
                    nm:"Transform"
                 }
              ],
              nm:"Group 1",
              np:0,
              cix:2,
              bm:0,
              ix:4,
              mn:"ADBE Vector Group",
              hd:false
           }
        ],
        ip:0,
        op:240,
        st:0,
        bm:0
     }
  ],
  markers:[

  ]
};
